.redemption-page-container{
  padding: 0;
}
.vibe-redemption-code-content {
  margin-top: 35px;
  margin-bottom: 50px;


  .card-body {
    padding: 30px 0 18px 25px;
    margin-right: 15px;
    text-align: left;
    border-radius: 15px;
  }

  .vibe-redemption-code-card-bottom {
    font: normal normal normal 16px Roboto;
    color: var(--sh-purple-vibe);
    text-align: center;
    border-radius: 0 0 30px 30px;
    width: 80%;
    padding-bottom: 18px;
  }

  .vibe-redemption-code-card-top {
    padding: 15px;
    border-radius: 30px 30px 0 0;
    width: 80%;
  }

  h2 {
    font: normal normal bold 26px Roboto;
  }
}

.vibe-redemption-code-container {
  font: normal normal normal 18px Roboto;
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-bottom: 10px;
  padding: 0;
  background-color: var(--sh-soft-blue);
}

.voucher-image-redemption-code {
  img {
    width: 140px;
    border-radius: 15%;
  }
}

.voucher-name {
  font: normal normal 800 20px Roboto;
  margin-bottom: 11px;
  padding: 0 10px;
}

.voucher-price {
  font: normal normal normal 16px Roboto;
  margin-bottom: 11px;
  padding: 0 10px;
}

.voucher-code {
  .voucher-code-body {
    background-color: var(--sh-lighter);
    border-radius: 8px;
    margin: 0 30px 20px;

    h3 {
      font-size: 14px;
      padding: 23px;
      border-bottom: solid 2px var(--sh-light);
    }

    .redemption-code-button {
      font-family: "Courier New", "Roboto", sans-serif;
      padding: 15px;
      font-weight: bold;
      display: block;
      color: inherit;
      text-decoration: none;
      border-style: none;
      border-radius: 0;
      background-color: inherit;
      margin-top: 0;

      a {
        color: inherit;
        text-decoration: none;
      }

      &.hide {
        position: relative;
        :after {
          content: "";
          width: 33px;
          height: 25px;
          position: absolute;
          right: 15px;
          background-repeat: no-repeat;
          background-image: url("../../../../assets/images/eye-icon.svg");
        }
      }

      span {
        font-size: calc(min(90vw, 570px) / 26);
        line-height: 1;
      }
    }
  }
  .redemption-pin-code {
    font-weight: bold;
    .redemption-pin-code-value {
      font-size: 18px;
      display: block;
      font-family: "Courier New", "Roboto", sans-serif;
    }
    .redemption-pin-code-legend{
      font-size: 75%;
      display: block;
    }
  }
  .redemption-code-legend {
    font-size: 12px;
    font-weight: bold;
  }

  .voucher-code-button {
    border: none;
    border-radius: 30px;
    background-color: #6146CD;
    height: 60px;
    width: 308px;
    line-height: 60px;
    padding: 0 30px;
    text-align: center;
    color: #fff;
    margin: 10px auto;
    font: normal normal normal 16px/11px Roboto;
    transition: all 0.35s ease 0s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

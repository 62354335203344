body {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.sh-main {
  min-height: 100vh;
  // .sh-content { }

  .sh-footer {
    background-color: var(--sh-light);
    padding: 63px 0;
    font-size: 12px;
    color: var(--sh-dark);
  }
}

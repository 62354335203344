.redemption-code-button {
  width: 95%;
  border-radius: 30px;
  margin-top: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: var(--sh-cold-blue);
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;

  a {
    color: var(--sh-lighter);
    text-decoration: none;
  }
}

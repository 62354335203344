.redemption-vibe-footer {
  background-color: var(--sh-cold-blue);
  border-radius: 40px 40px 0px 0px;
  padding-top: 35px;
  width: 100%;
  height: 225px;

  .footer-line {
    display: inline-flex;
    color: var(--sh-lighter);
    border: 1px solid var(--sh-soft-vibe);
    width: 90%;
    opacity: 0.5;
    margin-bottom: 25px;
  }

  .redemption-vibe-footer-logo {
    text-align: left;
    padding-left: 50px;
    padding-bottom: 35px;
  }

  .redemption-vibe-footer-social {
    text-align: right;
    padding-right: 50px;
    img {
      margin: 6px;
    }
  }

  .footer-address {
    font-family: "Roboto", sans-serif;
    color: var(--sh-lighter);
    font-size: 12px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    padding-left: 35px;

    .footer-links {
      a {
        color: var(--sh-lighter);
      }
    }
  }
}

.redemption-page-container{
  padding: 0 5px;
}

.redemption-card {
  margin: 20px auto;
  background-color: var(--sh-light);
  width: 100%;
  max-width: 714px;
  border-radius: 17px;
  text-align: center;
  padding: 51px 0;
  margin-bottom: 135px;

  .card-container {
    max-width: 580px;
    margin: 0 auto;
  }

  .voucher-image {
    width: 261px;
    margin: 0 auto;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .voucher-name {
    font-size: 24px;
    color: #000000;
    margin-bottom: 11px;
    padding: 0 20px;
  }

  .voucher-price {
    font-size: 57px;
  }

  .folding {
    height: 90px;
    position: relative;

    hr {
      border: solid 1px var(--sh-dark);
      margin: 0 60px;
      position: relative;
      top: 50%;
    }

    &:after,
    &:before {
      content: "";
      width: 90px;
      height: 90px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      background-color: white;
    }

    &:before {
      right: -45px;
    }

    &:after {
      left: -45px;
    }
  }

  
}

.redemption-code-card {
  width: 65%;
  border-radius: 5px;
  .redemption-code-button {
    margin-top: 10px;
    width: 60%;
    border-radius: 5px;
    box-shadow: 5px 5px var(--sh-lighter);
    border-color: #0F945B;
    background-color: #0F945B;
    a {
      color: var(--sh-lighter);
      text-decoration: none;
    }
  }
}
.redemption-footer{
    background-color: var(--sh-light);
    padding-top: 63px;
    padding-bottom: 155px;

    .redemption-footer-container{
        margin: 0 50px;
        max-width: 830px;
        font-size: 12px;
        line-height: 20px;
        color: var(--sh-dark);
    }
}
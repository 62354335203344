:root {
  @each $c, $v in $colors {
    --sh-#{$c}: #{$v};
  }
}

@each $c,$v in $colors {
  .sh-bg-#{$c} {
    background-color: #{$v} !important;
  }

  .sh-text-#{$c} {
    color: #{$v} !important;
  }
}

@each $c,$v in $colors {
  .btn-#{$c} {
    background-color: #{$v};

    &:hover, &:focus{
      background-color: lighten($v, 7%);
    }

    &:active{
      background-color: darken($v, 7%);
    }
  }
}